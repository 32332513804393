/* eslint-disable import/first */

/**
 * @copyright © 2020, Starberry Ltd
 */
import React from 'react';
 import { withSubtheme } from '@starberryComponentsMui';

import defaults from './defaults';

import { LogoOrLogotype } from './index';
import { Typography } from '@mui/material';

export const Logotype = withSubtheme(props => {
    const { className } = props;

    return <Typography classes={{root: className}}>
        <LogoOrLogotype type1='logoSecondary' type2='logo' {...props} altClassName="dialogLogo" className="dialog-logo" />
        </Typography>
}, 'logoLogin', defaults)

Logotype.propTypes = {
//    wrapper: PropTypes.bool
};

Logotype.defaultProps = {
//    wrapper: true
};

export default Logotype;