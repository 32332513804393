import React from 'react';
import { withSubtheme } from '@starberryComponentsMui';
import Grid from '@mui/material/Grid';
import Unsubscribe from "@components/MyAccount/modals/unsubscribe";

const MyAccountUnsubscribe = withSubtheme((props) => {
    const {token, location} = props;

    return (
        <Grid container>
            <Grid item xs={12}>
                <Unsubscribe onClose={() => void 0} token={token} location={location} />
            </Grid>
        </Grid>
    )
}, 'unsubscribePage')

export default React.memo(MyAccountUnsubscribe);
