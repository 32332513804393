import React from 'react';
import Button from '@mui/material/Button';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';

// Icons
import Alert from '@myaccount/alert';
import { doRedirect } from "@myaccount/utils"
import { MYACCOUNT_DASHBOARD } from "@myaccountUrls";
import { useUserObjectState } from "@myaccountServices"

const StyledDialogContent = (props) => {
    const {
        subtitle,
        successText,
        dialogContentProps,
        token,
    } = props

    const { state, services } = useUserObjectState();

    const success = !!state.success && !state.error;

    const {
        dialogContentTitleProps,
        dialogActionsBtnProps,
        dialogCancelActionBtnProps
    } = dialogContentProps

    const handleSubmit = (event, value) => {
        event.preventDefault();
        if (value) {
            services.unsubscribe({token:token});
        } else {
            doRedirect(MYACCOUNT_DASHBOARD)
        }
    };

    return(
        <DialogContent classes={{root: "dialogContent"}}>
            <DialogContentText classes={{root: "dialogContentTitle"}} {...dialogContentTitleProps}>
                {success ? successText : subtitle}
            </DialogContentText>

            {!success && (
                <form noValidate autoComplete="off">
                    <Alert type="alert" message={state.message} severity={state.error ? 'error' : 'success'} />

                    <DialogActions classes={{root: "dialogActionsConfirm"}}>
                        <Button {...dialogActionsBtnProps} classes={{root: "dialogActionsConfirmBtn"}} onClick={(event) => {handleSubmit(event, true)}}>
                            Yes
                        </Button>
                        <Button {...dialogCancelActionBtnProps} classes={{root: "dialogActionsConfirmBtn"}} onClick={(event) => {handleSubmit(event, false)}}>
                            No
                        </Button>
                    </DialogActions>
                </form>
            )}
            {success && (
                <DialogActions classes={{root: "dialogActionsConfirm"}}>
                    <Button {...dialogActionsBtnProps} classes={{root: "dialogActionsConfirmBtn"}} href={MYACCOUNT_DASHBOARD}>
                    Return to Dashboard
                    </Button>
                </DialogActions>
            )}
        </DialogContent>
    )
};

export default StyledDialogContent;
