import React from 'react';
import { withSubtheme } from '@starberryComponentsMui';
// MUI imports
import Dialog from '@mui/material/Dialog';
import useMediaQuery from '@mui/material/useMediaQuery';

// Components
import StyledDialogTitle from '../title';
import StyledDialogContent from './content';
import Logo from '@myaccount/Logo/LogoLogin';

import defaults from '../defaults';

// My Account
import { UserObjectStoreProvider } from "@myaccountServices"

const UnsubscribeModal = withSubtheme(props => {

    const {
        theme,
        className,
        dialogTitleProps,
        dialogContentProps,
        unsubscribe,
        token,
        logoType
    } = props

    const {
        fullScreen,
        data
    } = unsubscribe

    const {
        setOpenDefault,
        successText,
        loginTitle,
        loginSubtitle,
        showSocialLogin,
        resetPassLabel,
        resetPassUrl,
        showTerms,
        termsText,
        formActionLabel,
    } = data


    let loginSubtitleSub = null;
    if (undefined !== loginSubtitle)
        loginSubtitleSub = <>{loginSubtitle}</>;

    // logic for opening/closing dialog
    const [open, setOpen] = React.useState(setOpenDefault);
    const fullScreenBreakpoint = fullScreen ? useMediaQuery(theme.breakpoints.down(fullScreen)) : false;

    const handleClose = () => {
        if (true === setOpenDefault) {
            return null
        } else {
            setOpen(false);
        }
    };

    return (
        <>
            <UserObjectStoreProvider>
                <Dialog
                    fullScreen={fullScreenBreakpoint}
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="unsubscribe-dialog"
                    classes={{root: className}}
                    BackdropProps={{ classes: {root: "backDrop"} }}
                >
                    <Logo type1={logoType || 'logo'} />
                    <StyledDialogTitle
                        id="unsubscribe-responsive-dialog-title"
                        title={loginTitle}
                        dialogTitleProps={dialogTitleProps}
                    />
                    <StyledDialogContent
                        subtitle={loginSubtitleSub}
                        successText={successText}
                        showSocialLogin={showSocialLogin}
                        showTerms={showTerms}
                        termsText={termsText}
                        resetPassUrl={resetPassUrl}
                        resetPassLabel={resetPassLabel}
                        formActionLabel={formActionLabel}
                        dialogContentProps={dialogContentProps}
                        token={token}
                    />
                </Dialog>
            </UserObjectStoreProvider>
        </>
    );
}, 'loginSignup', defaults)

export default React.memo(UnsubscribeModal);
